@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Teko;
    src: url(./assets/fonts/Teko.ttf);
}

@font-face {
    font-family: Inter;
    src: url(./assets/fonts/Inter.ttf);
}

body {
    font-family: Teko;
    background-color: #f5f5f5;
}

.inter {
    font-family: Inter !important;
}

.ant-spin .ant-spin-dot-item {
    background-color: white !important;
}